import Swiper from 'swiper/dist/js/swiper.js';
import AOS from 'aos';
import Blazy from 'blazy';
//import cookieBar from './cookie/jquery.cookiebar.js';
import jqueryui from './jquery-ui/jquery-ui.min.js';
import photobox from './photobox/photobox/jquery.photobox.js';
import datepicker_ita from './lingue_calendario/jquery.ui.datepicker-ita.min.js';
import datepicker_eng from './lingue_calendario/jquery.ui.datepicker-eng.min.js';


jQuery(document).ready(function( $ ) {

  var mySwiperTop = new Swiper ('.gallery_top', {
        direction: 'horizontal',
        effect: 'fade',
        autoplay: {
          delay: 2000,
        },
        speed: 1500,
        pagination: {
          clickable: true,
          el: '.gallery_top .swiper-pagination',
          type: 'bullets',
        },
        autoplay: {
          disableOnInteraction: true
        },
        loop: true
    });



  var myIconSlide = new Swiper ('.part-slide', {
       direction: 'horizontal',
       speed: 1300,
       spaceBetween: 2,
       autoplay: {
         delay: 800,
       },
       pagination: {
         clickable: true,
         el: '.part-slide .swiper-pagination',
         type: 'bullets',
       },
       effect: 'slide',
       loop: true,
       slidesPerView: 1,
        });

        var mySwiperOfSlide = new Swiper ('.off-slide', {
          direction: 'horizontal',
          speed: 1300,
          spaceBetween: 10,
          autoplayDisableOnInteraction: true,
          autoplay: {
            delay: 1700,
          },
          pagination: {
            clickable: true,
            el: '.cont-off-slide .swiper-pagination',
            type: 'bullets',
          },
          loop: true,
          slidesPerView: 1,
          lazy: true,
          preloadImages:false,
          navigation: {
            nextEl: '.slider_offerte .swiper-button-next',
            prevEl: '.slider_offerte .swiper-button-prev'
          },

          });


         $('body').photobox('.photobox', {
           autoplay:false,
           zoomable:false,
           hideFlash:false,
           thumbs:false
         });

        //cookie();

        $(".slider_more").click(function() {
          $(".slider_offerte").addClass('hide');
        });
        AOS.init();

        var bLazy = new Blazy();

         $('.hamb').click(function(){
            $('.cont-menu').addClass('opa');
            $('.menu-top ul li').addClass('anime');
            $('.logo div').addClass('short');
         });

         $('.ex').click(function(){
            $('.cont-menu').removeClass('opa');
            $('.menu-top ul li').removeClass('anime');
            $('.logo div').removeClass('short');
         });


         /*aggiungo classe al li se ha figli*/
         	$('.menu-top li .sub-menu').parent('li').addClass('dropdown');

         	$( ".menu-top li" ).each(function() {
         			if($( this ).hasClass( "dropdown" )){
         				$( this ).append('<div class=\"cont-fr\"><i class=\"fa fa-angle-down\" aria-hidden=\"true\"></i></div>');
         			}
         		});



          /*al click della freccia apro quello cliccato e chiudo gli altri*/
          $( ".cont-fr" ).each(function() {
          $(this).click(function(){
              if($(this).hasClass('rotate')){
                $(this).removeClass('rotate');
              }else{
                $( ".cont-fr" ).removeClass('rotate');
                $(this).addClass('rotate');
              }
            if($(this).parent().find('ul').hasClass("open")){
              $(this).parent().find('ul').removeClass( "open" );
            }else{
              $( ".menu-top ul li ul" ).removeClass( "open" );
                $(this).parent().find('ul').addClass( "open" );
            }
            });
          });


          $(".cont_show").click(function() {
            $('html, body').animate({
              scrollTop: ($("h1").offset().top-330)
            }, 1500);
          });


        var offset = 150;
        var $back_to_top = $('.cd-top');
       $(window).scroll(function(){
           if( $(this).scrollTop() > offset ){
             $back_to_top.addClass('cd-is-visible');
             $('body').addClass('scroll');
           } else {
             $back_to_top.removeClass('cd-is-visible cd-fade-out');
             $('body').removeClass('scroll');
           }
       });

        $('.filter').on( "click", function() {
        var filterValue = $(this).attr('data-filter');
        $(".filter").removeClass('active');
        $(this).addClass('active');
        $( ".gallery_isotope li" ).removeClass('hide');

        $( ".gallery_isotope li" ).each(function() {
              var filterClass =  $(this).attr('class');
                if(filterClass != filterValue) $(this).addClass('hide');
                if(filterValue == '*') $( ".gallery_isotope li" ).removeClass('hide');
            }); 
        });

        var lang =  $('#lang').val();
        $.datepicker.setDefaults($.datepicker.regional[lang]);

        if (document.getElementById("qr_check_in_date")) {
          // exists.
          $("#notti").change(function() {
            $(".notti").html($("#notti option:selected").text());
          });
          $(".notti").html($("#notti option:selected").text());
        $( "#qr_check_in_date" ).datepicker({
        	dateFormat:"dd.mm.yy",
        	minDate: new Date(),

        	onSelect: function(dateText){
        		var date = $("#qr_check_in_date").datepicker("getDate");

        		$(".gg").html($.datepicker.formatDate("dd", date));
        		$(".mm").html($.datepicker.formatDate("M", date));
        		$(".aa").html($.datepicker.formatDate("yy", date));
        	}
        }).datepicker( "setDate", "d" );
        var date = $("#qr_check_in_date").datepicker("getDate");
        $(".gg").html($.datepicker.formatDate("dd", date));
        $(".mm").html($.datepicker.formatDate("M", date));
        $(".aa").html($.datepicker.formatDate("yy", date));
      }else{
        //codice qr 21 11 2019
        $("#notti_1").change(function() {
          $(".notti_1").html($("#notti_1 option:selected").text());
        });
        $(".notti_1").html($("#notti_1 option:selected").text());
        
        options = function(limit, select) {
          var html;
          for (i = 1; i <= limit; i++) {
              var selected = (i == select) ? "selected" : "";
              html += '<option value="' + i + '" ' + selected + '>' + i + '</option>';
          }
          return html;
      }
      $('select#notti_1').html(options(10, 1));
      $('.notti_1').text('1');
        $( "#data_arrivo" ).datepicker({
        	dateFormat:"dd.mm.yy",
        	minDate: new Date(),

        	onSelect: function(dateText){
        		var date = $("#data_arrivo").datepicker("getDate");

        		$(".gg").html($.datepicker.formatDate("dd", date));
        		$(".mm").html($.datepicker.formatDate("M", date));
            $(".aa").html($.datepicker.formatDate("yy", date));
            
            notti = calcola_notti();
            $('.notti_1').val(notti);
        	}
        }).datepicker( "setDate", "d" );
        var date = $("#data_arrivo").datepicker("getDate");
        $(".gg").html($.datepicker.formatDate("dd", date));
        $(".mm").html($.datepicker.formatDate("M", date));
        $(".aa").html($.datepicker.formatDate("yy", date));

        function calcola_notti() {

          var alternate_data_inizio = $('#data_arrivo').val();
          var alternate_data_fine = $('#data_partenza').val();
  
          alternate_data_inizio = alternate_data_inizio.split('.');
          var anno_i = alternate_data_inizio[2];
          var mese_i = alternate_data_inizio[1];
          var giorno_i = alternate_data_inizio[0];
  
          fromdate = new Date(anno_i, mese_i - 1, giorno_i);
 
          var date = $("#data_arrivo").datepicker("getDate");
          $("#gg").val($.datepicker.formatDate("dd", date));
          $("#mm").val($.datepicker.formatDate("mm", date));
          $("#aa").val($.datepicker.formatDate("yy", date));  
        }
      }

        $(document).click(function(e){
        $('.cont_pop').css('display', 'none');
        });

        $('.cont_pop p a').click(function(e) {
          e.stopPropagation();
        });
});


  function prenota(){
    var split_arrivo = $("#datepicker").val();

     split_arrivo = split_arrivo.split('/');
     var aa_arrivo = split_arrivo[2];
     var mm_arrivo = split_arrivo[1];
     var gg_arrivo = split_arrivo[0];

     $('[name=aa]').val(aa_arrivo);
     $('[name=mm]').val(mm_arrivo);
     $('[name=gg]').val(gg_arrivo);


     var split_partenza = $("#datepicker_partenza").val();
      split_partenza = split_partenza.split('/');
      var aa_partenza = split_partenza[2];
      var mm_partenza = split_partenza[1];
      var gg_partenza = split_partenza[0];


    var fromdate = new Date( aa_arrivo, mm_arrivo-1, gg_arrivo);

     var todate = new Date( aa_partenza, mm_partenza-1, gg_partenza);
     var giorni_differenza = (todate-fromdate)/86400000;
     giorni_differenza = Math.round(giorni_differenza);

     $('[name=notti_1]').val(giorni_differenza);
  }

function video(video){

    $( "body" ).append( "<div class=\"cont_video\"><i class=\"fa fa-window-close\" aria-hidden=\"true\"></i><video controls=\"controls\" width=\"auto\" height=\"50%\" autoplay><source src=\""+video+"\" type=\"video/mp4\" />Your browser does not support the video tag.</video></div>" );

    $(".fa-window-close").click(function(){
      $(".cont_video").remove();
    });
}

function cookie(){

switch($('#lingua').val()){
  case 'ita':
    var testo = 'Questo sito utilizza cookie tecnici e di profilazione di terze parti. Per ulteriori informazioni o per negare il consenso, leggi la informativa estesa. Proseguendo con la navigazione acconsenti a usare i cookie.';
      var label = 'Accetta e chiudi';
  break;

  default:
    var testo = 'This site uses technical cookies and third party profiling. To learn more or opt out, read the complete cookie policy statement. By continuing to use this site, we assume you are happy with it';

    var label = 'Accept and close';
  break;
}
  $.cookieBar({
    message: testo,
    acceptText: label,
    policyButton: true,
    policyText: 'Privacy Policy',
    policyURL: $('#linkcookie').val(),
    expireDays: 30
  });
}
